import React from "react";
import { Provider } from "react-redux";
import store from "../coms/data/store";

import Seo from "../coms/global/Seo";
import MainFrame from "../coms/global/MainFrame";
import Layout from "../coms/global/Layout";
import RegForm from "../coms/user/RegForm";

const SignupPage = () => {
  return (
    <>
      <Seo title="Create A New Account" pathname="/signup" />
      <Provider store={store}>
        <MainFrame>
          <Layout>
            <RegForm />
          </Layout>
        </MainFrame>
      </Provider>
    </>
  );
};

export default SignupPage;
