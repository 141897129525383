import React, { useState } from "react";
import isEmail from "validator/lib/isEmail";
import isJWT from "validator/lib/isJWT";
import isStrongPassword from "validator/lib/isStrongPassword";

import { makeStyles } from "@mui/styles";
import {
  Container,
  Paper,
  Grid,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Avatar,
  Button,
  TextField,
  Link,
  Typography,
  Alert,
} from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import DateAdapter from "@mui/lab/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import LoadingPaper from "../tools/loadingPaper";
import { goHomePage } from "../tools/browserHelpers";

import UserAPI from "../api/userApi";
import { useSelector, useDispatch } from "react-redux";
import { setStatusMsg, setUser } from "../data/settings";

const RegForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const lang = useSelector((s) => s.settings.lang);
  const user = useSelector((s) => s.settings.user);
  const text = useSelector((s) => s.settings.texts.reg);

  const [busy, setBusy] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const [xFname, setName1] = useState("");
  const [xLname, setName2] = useState("");
  const [xPass, setPass] = useState("");
  const [xEmail, setEmail] = useState("");
  const [xGender, setGender] = useState("m");
  const [xBirthday, setBirthday] = useState(new Date("2000-01-01T12:00:00"));

  const hRegister = () => {
    dispatch(setStatusMsg([0, ""]));
    setErrMsg("");

    if (xFname.length < 3 || xLname.length < 3) {
      setErrMsg(text.nameReq);
      return;
    }

    if (isEmail(xEmail) === false) {
      setErrMsg(text.emailReq);
      return;
    }

    if (
      isStrongPassword(xPass, {
        minLength: 6,
        minLowercase: 0,
        minUppercase: 0,
        minNumbers: 0,
        minSymbols: 0,
      }) === false
    ) {
      setErrMsg(text.pwdReq);
      return;
    }

    setBusy(true);

    const data = {
      fname: xFname,
      lname: xLname,
      gender: xGender,
      birthday: xBirthday.toISOString().slice(0, 19).replace("T", " "),
      email: xEmail,
      pwd: xPass,
    };

    UserAPI.register(
      data,
      (m) => {
        setBusy(false);

        if (m.token !== undefined) {
          if (isJWT(m.token)) {
            dispatch(setStatusMsg([1, text.welcomeMsg]));
            dispatch(setUser(m));
            goHomePage();
            return;
          }
        }

        dispatch(setStatusMsg([2, text.failedUser]));
      },
      (x) => {
        setBusy(false);
        if (x.e !== undefined) {
          dispatch(setStatusMsg([2, text.failedUser]));
          return;
        }
        dispatch(setStatusMsg([3, "server"]));
      }
    );
  };

  if (user) {
    goHomePage();
    return <></>;
  }

  if (text) {
    return (
      <Container maxWidth="xs" className={classes.root}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {text.title}
          </Typography>

          <div className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  title=""
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  value={xFname}
                  onChange={(event) => {
                    setName1(event.target.value);
                  }}
                  label={text.name1}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  title=""
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  name="lastName"
                  autoComplete="lname"
                  value={xLname}
                  onChange={(event) => {
                    setName2(event.target.value);
                  }}
                  label={text.name2}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  title=""
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  value={xEmail}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  label={text.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  title=""
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={xPass}
                  onChange={(event) => {
                    setPass(event.target.value);
                  }}
                  label={text.pass}
                />
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DatePicker
                    label={text.birthday}
                    value={xBirthday}
                    onChange={(date) => {
                      setBirthday(date);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">{text.gender}</FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name="gender"
                    value={xGender}
                    onChange={(event) => {
                      setGender(event.target.value);
                    }}
                  >
                    <FormControlLabel
                      value={"m"}
                      label={text.male}
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value={"f"}
                      label={text.female}
                      control={<Radio />}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {errMsg.length > 0 && <Alert severity="error">{errMsg}</Alert>}
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={busy}
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    hRegister();
                  }}
                  className={classes.submit}
                >
                  {text.reg}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">{text.agree}</Typography>
                <Typography variant="body2">
                  <a
                    target="_blank"
                    href={lang.name === "ar" ? "/ar/terms" : "/en/terms"}
                  >
                    {text.terms}
                  </a>
                  {" / "}
                  <a
                    target="_blank"
                    href={lang.name === "ar" ? "/ar/privacy" : "/en/privacy"}
                  >
                    {text.privacy}
                  </a>
                </Typography>
              </Grid>
            </Grid>

            <Grid container padding="15px 0px" justify="flex-end">
              <Grid item>
                <Link href="/signin" variant="body2">
                  {text.signinNote}
                </Link>
              </Grid>
            </Grid>
          </div>
        </Paper>

        <LoadingPaper open={busy} />
      </Container>
    );
  }

  return <></>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    position: "relative",
    padding: theme.spacing(5, 1),
  },
  paper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(3),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  errmsg: {
    width: "100%",
    border: "1px #f00 solid",
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  okmsg: {
    width: "100%",
    border: "1px #0f0 solid",
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default RegForm;
